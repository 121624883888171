<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Filter </v-col>
            <v-col class="shrink pr-6">
              <v-btn
                icon
                @click="
                  dialog = false;
                  thisFilters = { ...filters };
                "
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>

      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Comments </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.comments"
              outlined
              hide-details
              placeholder="Filter by comments..."
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Created Date </v-col>
          <v-col class="pb-2" cols="6">
            <v-menu
              v-model="createdFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdFrom"
                no-title
                :max="createdFromMax"
                @input="createdFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="createdToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.createdTo"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.createdTo"
                :min="createdToMin"
                no-title
                @input="createdToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Created By </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="thisFilters.createdByIds"
              multiple
              label="Select user(s)"
              hide-details
              :items="users"
              item-text="fullname"
              clearable
              deletable-chips
              @change="thisFilters.createdByIds = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="clear()"> clear </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn class="white msaBlue--text" @click="apply()">
                apply
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EmployeeNotesFilter',
  props: {
    filters: {
      type: Object,
    },
    users: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: JSON.parse(
        JSON.stringify(this.$constants.EMPLOYEE_NOTES_DEFAULT_FILTERS),
      ),
      createdFromMenu: false,
      createdToMenu: false,
    };
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.thisFilters) !=
        JSON.stringify(this.$constants.EMPLOYEE_NOTES_DEFAULT_FILTERS)
      );
    },

    createdToMin() {
      return this.thisFilters.createdFrom == ''
        ? undefined
        : this.thisFilters.createdFrom;
    },

    createdFromMax() {
      return this.thisFilters.createdTo == ''
        ? undefined
        : this.thisFilters.createdTo;
    },
  },
  methods: {
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.EMPLOYEE_NOTES_DEFAULT_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      Object.keys(this.thisFilters).forEach((k) => {
        this.thisFilters[k] = this.thisFilters[k] ?? '';
      });
    },
    setFilters() {
      this.thisFilters = JSON.parse(JSON.stringify(this.filters));
    },
  },
};
</script>

<style scoped>
canvas {
  border: 1px black solid;
  border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
