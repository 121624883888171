<template>
  <v-container fluid>
    <v-row>
      <v-col class="grow" align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="showNoteDetail()"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add a new note</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <EmployeeNotesFilter
          :users="users"
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text py-5">
            Notes
            <v-badge
              inline
              class="ml-2"
              bordered
              :content="itemsCount"
              :value="itemsCount"
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            must-sort
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="options"
          >
            <template v-slot:body="{ items }">
              <tr v-if="!items.length" class="v-data-table__empty-wrapper">
                <td
                  colspan="6"
                  class="subtitle-2 font-weight-regular px-4"
                  style="height: 48px"
                  :align="$vuetify.breakpoint.xs ? 'start' : ''"
                >
                  No results found
                </td>
              </tr>
              <v-slide-y-transition group tag="tbody">
                <template v-for="item in items">
                  <tr :key="item.id">
                    <td>
                      {{ item.comments }}
                    </td>
                    <td align="center">
                      {{ item.createdAt | filterAsLocalDate }}
                    </td>
                    <td align="center">
                      {{ item.createdByName }}
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            text
                            small
                            v-on="on"
                            color="msaBlue"
                            @click="showNoteDetail(item)"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            text
                            small
                            color="red"
                            v-on="on"
                            @click="deleteNote(item)"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-slide-y-transition>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <EmployeeNoteDetail
      :item="selectedItem"
      ref="noteDetails"
      :key="reloadDetails"
      @close="reloadDetails = $helpers.createGuid()"
      @updated="getItems()"
    />
  </v-container>
</template>

<script>
import EmployeeNotesFilter from '@/components/Filters/EmployeeNotesFilter.vue';
import EmployeeNoteDetail from '@/components/EmployeeNotes/EmployeeNoteDetail.vue';
export default {
  name: 'UserNotesPage',
  components: {
    EmployeeNotesFilter,
    EmployeeNoteDetail,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      users: [],
      items: [],
      itemsCount: 0,
      filters: {
        ...this.$constants.EMPLOYEE_NOTES_DEFAULT_FILTERS,
      },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      reloadDetails: this.$helpers.createGuid(),
      headers: [
        {
          text: 'Comments',
          value: 'comments',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '200px',
        },
        {
          text: 'Created By',
          value: 'createdByName',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '110px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
          width: '140px',
        },
      ],
      selectedItem: {
        id: -1,
        name: '',
        comments: '',
      },
    };
  },
  methods: {
    getItems() {
      const params = {
        loaderText: 'loading...',
        filters: this.filters,
        options: this.options,
        employeeId: this.$route.params.id,
      };

      const url = 'get-employee-notes?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
          this.users = response.data.users;
          this.$store.commit('updateSelectedUser', {
            ...response.data.user,
            status: response.data.user.status == 'Active' ? 1 : 0,
          });
        })
        .catch((error) => error);
    },
    applyFilters(filters) {
      this.filters = { ...filters };
      this.options.page = 1;
      this.updateUrl();
    },
    getRouteQueries() {
      const query = this.$route.query;

      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'createdAt', true);

      this.getItems();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.EMPLOYEE_NOTES_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'createdAt',
        true,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    showNoteDetail(note) {
      if (note == undefined) {
        this.selectedItem = {
          id: -1,
          name: '',
          comments: '',
        };
      } else {
        this.selectedItem = note;
      }

      this.$nextTick(() => {
        this.$refs.noteDetails.show();
      });
    },
    deleteNote(note) {
      const confirm = () => {
        const params = {
          loaderText: 'Deleting...',
          employeeId: this.$route.params.id,
          id: note.id,
        };
        const url = 'delete-employee-note?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.getItems();
          })
          .catch((error) => error);
      };
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this note?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      this.$refs.filters.setFilters();
    });
  },
};
</script>

<style></style>
