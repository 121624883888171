<template>
  <v-dialog v-model="dialog" width="750px" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text"> Details </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" lazy-validation>
          <v-row dense>
            <v-col class="font-weight-bold"> Comments: </v-col>
          </v-row>
          <v-row dense>
            <v-col class="py-0">
              <v-textarea
                class="py-0"
                outlined
                counter
                dense
                maxlength="2000"
                v-model="edited.comments"
                :rules="rules.comments"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()"> Cancel </v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="saveDetail()"
          :disabled="disableSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EmployeeNoteDetail',
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    isNew() {
      return this.item.id == -1;
    },
    disableSave() {
      if (this.isNew) {
        return this.edited.comments.trim() == '';
      } else {
        return (
          this.edited.comments.trim() == this.item.comments ||
          this.edited.comments.trim() == ''
        );
      }
    },
  },
  watch: {},
  data() {
    return {
      edited: {
        id: -1,
        comments: '',
      },
      dialog: false,
      valid: false,
      rules: {
        comments: [(v) => this.$helpers.required(v, 'Comments')],
      },
    };
  },
  methods: {
    saveDetail() {
      if (this.$refs.form.validate()) {
        const params = JSON.parse(JSON.stringify(this.edited));
        params.loaderText = 'Saving...';
        params.employeeId = this.$route.params.id;

        const url = this.isNew
          ? 'add-employee-note?format=json'
          : 'update-employee-note?format=json';

        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('updated');
            this.close();
          })
          .catch((error) => error);
      }
    },
    show() {
      this.edited = JSON.parse(JSON.stringify(this.item));
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>
<style scoped></style>
